<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card max-width="380" class="mx-auto elevation-6">
        <v-layout align-center justify-center column class="pt-12 py-4 px-12">
          <v-img :src="require('@/assets/images/logo.png')"></v-img>
          <br />
          <div class="subtitle-1">Sign into your account</div>
        </v-layout>
        <div class="pt-1 pa-6">
          <v-tabs v-model="tab" class="no-tab-bar">
            <v-tabs-items v-model="tab">
              <v-tab-item value="username-tab">
                <v-form ref="form" @submit.prevent="checkEmail">
                  <v-text-field
                    autofocus
                    outlined
                    clearable
                    required
                    v-model="form.email"
                    label="Email or phone number"
                    :error-messages="form.$getError('email')"
                    data-testid="login-email-input"
                  ></v-text-field>
                  <v-btn
                    block
                    large
                    color="bg-primary-gradient primary"
                    type="submit"
                    class="my-1"
                    :loading="form.$busy"
                    data-testid="login-email-submit"
                    >Submit</v-btn
                  >
                </v-form>
              </v-tab-item>
              <v-tab-item value="password-tab">
                <div class="text-center mb-8">
                  <div v-if="form.email">
                    <v-chip close @click:close="notYou" outlined>
                      <v-avatar left>
                        <img
                          :src="require('@/assets/images/default-profile.png')"
                          alt
                        />
                      </v-avatar>
                      {{ form.email }}
                    </v-chip>
                  </div>
                </div>
                <v-form ref="form" @submit.prevent="signIn">
                  <v-text-field
                    autofocus
                    outlined
                    required
                    type="password"
                    label="Password"
                    v-model="form.password"
                    :error-messages="form.$getError('password')"
                    data-testid="login-password-input"
                  ></v-text-field>
                  <v-btn
                    block
                    large
                    color="bg-primary-gradient primary"
                    type="submit"
                    :loading="form.$busy"
                    data-testid="login-password-submit"
                    >Sign in</v-btn
                  >
                </v-form>
                <v-layout class="mt-4">
                  <v-btn
                    text
                    color="primary"
                    block
                    :to="{ name: 'auth.forgot-password' }"
                    >Forgot password ?</v-btn
                  >
                </v-layout>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </div>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import Cookies from 'js-cookie'
import Form from '@/utils/form'

export default {
  name: 'LoginPage',

  data() {
    return {
      tab: 'username-tab',
      form: new Form({
        email: null,
        password: null,
      }),
    }
  },

  computed: {
    redirectPath() {
      return Cookies.get('EXPIRED_TOKEN_REDIRECT_PATH')
    },
  },

  methods: {
    checkEmail() {
      this.form.$clearErrors()
      this.form.$busy = true
      this.$api
        .post('auth/check-email', { email: this.form.email })
        .then(({ data }) => {
          this.form.$busy = false
          this.showPasswordTab()
        })
        .catch((err) => {
          this.form.$busy = false
          if (err.response.status === 404) {
            this.form.$setErrors({ email: err.response.data.message })
          } else if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    signIn() {
      this.form.$clearErrors()
      this.form.$busy = true
      this.$api
        .post('auth/login', {
          username: this.form.email,
          password: this.form.password,
        })
        .then(({ data }) => {
          this.form.$busy = false
          this.$store.commit('auth/setToken', data.data)
          let path = this.redirectPath.startsWith('/auth/')
            ? '/'
            : this.redirectPath

          this.$router.replace({ path })
        })
        .catch((err) => {
          this.form.$busy = false
          if (err.response.status === 401) {
            this.form.$setErrors({ password: err.response.data.message })
          } else if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    notYou() {
      this.clearForm()
    },

    showUsernameTab() {
      this.tab = 'username-tab'
    },

    showPasswordTab() {
      this.tab = 'password-tab'
    },

    clearForm() {
      this.form.$reset()
      this.showUsernameTab()
    },
  },
  watch: {
    'form.email'() {
      this.form.$clearError('email')
    },
    'form.password'() {
      this.form.$clearError('password')
    },
  },
}
</script>
